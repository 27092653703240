import React from 'react';
import { bool, string, func } from 'prop-types';
import withPortal from 'oc-core-components/src/WithPortal';
import { isFunction } from 'helpers/utils';
import { mapProps, compose } from 'recompose';



import { withModal, modalContextPropTypes } from './ModalProvider';
import ModalInner from './ModalInner';


const ModalPortal = withPortal({ root: 'modal-root' })(ModalInner);

const withMapProps = mapProps((props) => {
  const {
    onCloseClick,
    onClose,
    onOverlayClick,
    disableClose,
    modal,
    ...restProps
  } = props;



  let {
    disableOverlayClose,
    disableXClose,
  } = props;

  if (disableClose) {
    disableXClose = true;
    disableOverlayClose = true;
  }

  const close = (cb, closeModal) => (e) => {
    if (closeModal) {
      modal.close();

      if (isFunction(onClose)) onClose(e, modal);
    }
    if (isFunction(cb)) cb(e, modal);
  };

  return {
    onCloseClick: close(onCloseClick, !disableXClose),
    onOverlayClick: close(onOverlayClick, !disableOverlayClose),
    modal,
    ...restProps,
  };
});

const Modal = ({
  name,
  children,
  modal,
  component: Component,
  ...restProps
}) => (
  <Component
    isDisplayed={modal.activeModal === name}
    {...restProps}
  >
    { isFunction(children) ? children(modal) : children }
  </Component>
);

// disableXClose - to disable modal closing on "x" click
// disableOverlayClose - to disable modal closing on overlay click
// disableClose - to disable any options of modal closing (both "x" click and overlay click)
Modal.propTypes = {
  name: string.isRequired,
  modal: modalContextPropTypes.isRequired,
  disableClose: bool,
  disableXClose: bool,
  disableOverlayClose: bool,
  onCloseClick: func, // close button is clicked
  onClose: func, // modal is closed
  onOverlayClick: func, // overlay is clicked
  size: string,
};

Modal.defaultProps = {
  component: ModalPortal,
}

export default compose(
  withModal,
  withMapProps,
)(Modal);
