import createAction from 'v2/redux/utils/createAction';
import { createLoadingActions } from 'v2/redux/loading/actions';
import { getPathFromUrl } from 'helpers/utils';
import types from './types';

export const setV2CurrentPage = createAction(types.SET_CURRENT_PAGE);
export const fetchV2PageSectionsList = createAction(types.FETCH_PAGE_SECTIONS_LIST.SELF);

export const storeV2Meta = createAction(types.STORE_META, payload => ({ meta: payload }), (payload, m = 'replace') => ({ mergingStrategy: m }));
export const storeV2PageProps = createAction(types.STORE_PAGE_PROPS, (pageId, payload) => ({ pageProps: payload }), (pageId, payload, m = 'replace') => ({ mergingStrategy: m, pageId }));

export const loadingPageSectionsList = createLoadingActions('sections');
export const loadingAdsenseScript = createLoadingActions('adsenseScript');

export const removeIdsFromPageListsData = createAction(types.REMOVE_IDS_FROM_PAGE_LIST_DATA, (ids, uid, deleteAll) => ({ ids, uid, deleteAll }));
export const insertIdsInPageListsData = createAction(types.INSERT_IDS_IN_PAGE_LIST_DATA, (ids, position, uid) => ({ ids, position, uid }));

export const fetchV2PageSection = createAction(
  types.ACCUMULATE_FETCH,
  ({ url, page, ...restData }) => restData,
  ({ url, page }) => ({
    resource: 'sections',
    url: getPathFromUrl(url),
    page,
    // TODO: best practices???
    actions: {
      callback: fetchV2PageSectionsList,
      loading: loadingPageSectionsList,
    },
  }),
);

export const highlightUnlockQuestionBtnOn = createAction(types.HIGHLIGHT_UNLOCK_QUESTION_BTN_ON);
export const highlightUnlockQuestionBtnOff = createAction(types.HIGHLIGHT_UNLOCK_QUESTION_BTN_OFF);

export const setUserFeedback = createAction(types.SET_USER_FEEDBACK);

export const closeSearchOverlay = createAction(
  types.SET_SEARCH_OVERLAY,
  () => false,
);

export const openSearchOverlay = createAction(
  types.SET_SEARCH_OVERLAY,
  () => true,
);

export const toggleSearchOverlay = createAction(types.SET_SEARCH_OVERLAY);

export const setStudyModeOff = createAction(
  types.SET_STUDY_MODE,
  () => false,
);
export const setStudyModeOn = createAction(
  types.SET_STUDY_MODE,
  () => true,
);
export const toggleStudyMode = createAction(types.SET_STUDY_MODE);

export const setStudyModeTooltipClosed = createAction(
  types.SET_STUDY_MODE_TOOLTIP,
  () => false,
);
export const setStudyModeTooltipOpen = createAction(
  types.SET_STUDY_MODE_TOOLTIP,
  () => true,
);
export const toggleStudyModeTooltip = createAction(types.SET_STUDY_MODE_TOOLTIP);

export const updateTopicFollow = createAction(
  types.UPDATE_TOPIC_FOLLOW,
);
export const updateAvatar = createAction(types.UPDATE_AVATAR);

export const updateWatched = createAction(types.UPDATE_WATCH);

export const updatePaymentForm = createAction(types.UPDATE_PAYMENT_FORM);
export const resetPaymentForm = createAction(types.RESET_PAYMENT_FORM);

export const removeLatestEnrollmentById = createAction(types.REMOVE_LATEST_ENROLLMENT_BY_ID);
export const storeV2HotCourses = createAction(types.STORE_HOT_COURSES);
export const storeV2ShowHotCoursesBanner = createAction(types.STORE_SHOW_HOT_COURSES_BANNER);

export default {};
