import React, { Component } from 'react';
import {
  node, func, bool, oneOf, number, string,
} from 'prop-types';

import config from 'config';
import { dummyFunc } from 'helpers/utils';
import ModalLayout from './ModalLayout';

class ModalInner extends Component {
  constructor(props) {
    super(props);

    this.modalRef = React.createRef();
  }

  handleOverlayClick = (e) => {
    const { onOverlayClick } = this.props;

    const modal = this.modalRef.current;

    const isInsideModal = e.target === modal || modal.contains(e.target);

    if (!isInsideModal) {
      onOverlayClick(e);
    }
  }

  render() {
    const {
      children,
      size,
      noPadding,
      mobileCover,
      hideClose,
      onCloseClick,
      onGoBackClick,
      showGoBack,
      steps,
      hideSteps,
      title,
      titleClassName,
      centerOnMobile,
      className,
      innerClassName,
      activeStep: initialActiveStep,
    } = this.props;


    // ensure 0 < activeStep < steps
    // eslint-disable-next-line no-nested-ternary
    const activeStep = (initialActiveStep >= steps) ? steps : (initialActiveStep < 0 ? 0 : initialActiveStep);

    const showSteps = !hideSteps && (steps > 0);
    const showToolbar = showSteps || showGoBack || !hideClose;

    return (
      <ModalLayout
        modalRef={this.modalRef}
        handleOverlayClick={this.handleOverlayClick}
        size={size}
        noPadding={noPadding}
        mobileCover={mobileCover}
        hideClose={hideClose}
        onCloseClick={onCloseClick}
        onGoBackClick={onGoBackClick}
        showGoBack={showGoBack}

        steps={steps}
        showSteps={showSteps}
        showToolbar={showToolbar}

        title={title}
        titleClassName={titleClassName}
        centerOnMobile={centerOnMobile}
        className={className}
        innerClassName={innerClassName}
        activeStep={activeStep}
      >
        {children}
      </ModalLayout>
    );
  }
}

ModalInner.propTypes = {
  children: node,
  onCloseClick: func,
  onGoBackClick: func,
  hideClose: bool,
  showGoBack: bool,
  steps: number,
  activeStep: number,
  size: oneOf(config.modalSizes), // modal container size (e.g. sm, md, lg)
  title: node,
  noPadding: bool,
  onOverlayClick: func,
  hideSteps: bool,
  mobileCover: bool,
  centerOnMobile: bool,
  innerClassName: string,
};

ModalInner.defaultProps = {
  size: config.modalSizes[0],
  onCloseClick: dummyFunc,
  onGoBackClick: dummyFunc,
  onOverlayClick: dummyFunc,
  activeStep: 0,
  mobileCover: true,
};

export default ModalInner;
