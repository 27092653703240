import React from 'react';
import {
  node, func, bool, oneOf, number, string,
} from 'prop-types';

import cn from 'classnames';
import config from 'config';

import { dummyFunc } from 'helpers/utils';

import Icon from 'oc-core-components/src/Icon';
import Row from 'oc-core-components/src/Row';
import Col from 'oc-core-components/src/Col';

import withAmpStyles from 'helpers/withAmpStyles';
// eslint-disable-next-line import/order
import { stylesProxy } from 'helpers/css';
import stylesObj from './Modal.module.scss';
import ampStylesObj from './Modal.module.scss?amp&type=resolve';

const s = stylesProxy(stylesObj, 'Modal');

const ModalLayout = ({
  modalRef,
  handleOverlayClick,
  children,
  size,
  noPadding,
  mobileCover,
  hideClose,
  onCloseClick,
  onGoBackClick,
  showGoBack,

  steps,
  showSteps,
  showToolbar,

  title,
  titleClassName,
  centerOnMobile,
  className,
  innerClassName,
  activeStep,
  styles,

  closeProps,
  overlayProps,
  goBackProps,

}) => {
  const containerClassNames = cn(
    className,
    styles.container,
    {
      [styles[size]]: !!size,
      [styles['no-padding']]: noPadding,
      [styles['mobile-cover']]: mobileCover,
      [styles['mobile-center']]: centerOnMobile,
    },
  );

  return (
    <>
      <div className={styles.wrapper}>
        <div
          onClick={handleOverlayClick}
          className={styles.overlay}
          role="button"
          {...overlayProps}
        />
        <div className={containerClassNames} ref={modalRef}>
          {
            showToolbar && (
              <div className={styles.toolbar}>
                <Row className="u-justify-content-between">
                  <Col xs="auto">
                    {
                      showGoBack && (
                        <Icon
                          icon="feather:chevron-left"
                          tag="button"
                          role="button"
                          onClick={onGoBackClick}
                          className={styles.tool}
                          size="sm"
                          {...goBackProps}
                        />
                      )
                    }
                  </Col>

                  <Col xs="auto">
                    <Row className="u-align-items-center">
                      {
                        showSteps && (
                          <Col xs="auto" className={styles.dots}>
                            {
                              (new Array(steps)).fill(null).map((_, indx) => <div key={indx} className={cn(styles.dot, { [styles['is-active']]: indx === activeStep })} />)
                            }
                          </Col>
                        )
                      }

                      {
                        !hideClose && (
                          <Col xs="auto">
                            <Icon
                              icon="feather:x"
                              tag="button"
                              role="button"
                              onClick={onCloseClick}
                              className={styles.tool}
                              size="sm"
                              data-test-id="js-test_close_modal"
                              {...closeProps}
                            />
                          </Col>
                        )
                      }
                    </Row>
                  </Col>
                </Row>
              </div>
            )
          }

          <div className={cn(styles.inner, innerClassName, { [styles['with-toolbar']]: showToolbar })}>
            {
              title && (
                <h2 className={cn(titleClassName, styles.title)}>{title}</h2>
              )
            }
            {children}
          </div>
        </div>
      </div>
    </>
  );
};

ModalLayout.propTypes = {
  children: node,
  onCloseClick: func,
  onGoBackClick: func,
  hideClose: bool,
  showGoBack: bool,
  steps: number,
  activeStep: number,
  size: oneOf(config.modalSizes), // modal container size (e.g. sm, md, lg)
  title: node,
  noPadding: bool,
  onOverlayClick: func,
  hideSteps: bool,
  mobileCover: bool,
  centerOnMobile: bool,
  innerClassName: string,
};

ModalLayout.defaultProps = {
  size: config.modalSizes[0],
  onCloseClick: dummyFunc,
  onGoBackClick: dummyFunc,
  onOverlayClick: dummyFunc,
  activeStep: 0,
  mobileCover: true,
};

export default withAmpStyles(ModalLayout, s, stylesObj, ampStylesObj);
