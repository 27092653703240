import React, {
  useMemo, createContext, useContext,
} from 'react';

import {
  shape, func, bool, string,
} from 'prop-types';

import { withContext } from 'helpers/hoc';

import { connect } from 'react-redux';
import { getV2IsLoggedIn } from 'v2/redux/meta/selectors';
import { useModal } from 'oc-core-components/src/Modal/ModalProvider';
import { getV2CurrentPage } from 'v2/redux/page/selectors';

// Create context with default fields
export const AuthContext = createContext({
  isLoggedIn: false,
  withAuth: () => {},
});

export const authContextPropTypes = shape({
  isLoggedIn: bool,
  withAuth: func,
});

export const withAuth = withContext(AuthContext, 'auth');

export const useAuth = () => useContext(AuthContext);

const AuthProvider = ({
  isLoggedIn,
  currentPage,
  children,
}) => {
  const modal = useModal();

  const loginCb = useMemo(() => {
    // TODO: extract page names to constants
    const map = {
      homeworkHelp: modal.openHwAuth,
    };

    return map[currentPage] || modal.openLogin;
  }, [currentPage, modal]);

  const value = useMemo(() => ({
    isLoggedIn,
    checkAuth: (cb, loginCbProps) => (isLoggedIn ? cb : () => loginCb(loginCbProps)),
  }), [isLoggedIn, loginCb]);

  return (
    <AuthContext.Provider
      value={value}
    >
      {children}
    </AuthContext.Provider>
  );
};

const mapStateToProps = state => ({
  isLoggedIn: getV2IsLoggedIn(state),
  currentPage: getV2CurrentPage(state),
});

AuthProvider.propTypes = {
  isLoggedIn: bool,
  currentPage: string,
};

export default connect(
  mapStateToProps,
)(AuthProvider);
